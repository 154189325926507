import { Card, Typography, List, Tooltip } from "antd";
import { Address } from "../components";
import React, {  } from "react";
import { SyncOutlined } from "@ant-design/icons";

const { Text, Link } = Typography;
const maxRankedPlayers = 10;

export default function LeaderboardCard({
    currentOwner,
    maxAttempts,
    address,
    mainnetProvider,
    loadingLeaderboard,
    loadingHistory,
    refreshLeaderBoard,
    rankedPlayers
}) {
    return (
        <Card title="Current Rankings"
            type="inner"
            loading={loadingLeaderboard || loadingHistory}
            style={{ textAlign: "start", width: 600, margin: '0px auto 24px' }}
            extra={(<a onClick={refreshLeaderBoard}><SyncOutlined /></a>)}
        >
            <List
                rowKey={item => item.blockNumber + item.newOwner + "_" + item.prevOwnerTimeSpent}
                dataSource={rankedPlayers.slice(0, maxRankedPlayers)}
                renderItem={(item) => {
                    return (
                        <List.Item>
                            <div style={{ float: 'left' }}>
                                {item.rank + 1}.&nbsp;&nbsp;
                                <Address address={item.user} ensProvider={mainnetProvider} />
                                {Number(item.user) == Number(currentOwner ? currentOwner : 0x0) ? (<Tooltip title="The Current Ethleader"><span>&nbsp;&nbsp;👑</span></Tooltip>) : null}
                                {Number(item.user) == Number(address) ? (<span>&nbsp;&nbsp;(That's you!)</span>) : null}
                            </div>
                            <div style={{ float: 'right' }}>
                                {item.bonus > 0 ? <Tooltip title="Time booster available">🚀&nbsp; {item.bonus}h&nbsp;&nbsp;</Tooltip> : null}
                                <Tooltip title="Attacks used">⚔️&nbsp; {item.attempts}</Tooltip> / {maxAttempts}&nbsp;
                                <Tooltip title="Total time spent this round">{item.time.toHHMMSS()}</Tooltip>
                            </div>
                        </List.Item>
                    );
                }}
            />
        </Card>
    );

}