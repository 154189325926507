import { Card, Typography, List } from "antd";
import { Address } from "../components";

const { Text } = Typography;
const maxThroneTakenEvents = 6;

const EVENT_THRONE_TAKEN = "throne_taken"
const EVENT_TIME_BOOSTER = "time_booster"

export default function EventsCard({
    mainnetProvider,
    loadingHistory,
    round,
    events,
    ethrone
}) {
    return (
        <Card title="Latest Events"
            loading={loadingHistory}
            type="inner"
            style={{ textAlign: "start", width: 600, margin: '0px auto 24px' }}>
            <List
                rowKey={item => item.blockNumber + "_" + item.prevOwner + "_" + item.newOwner + "_" + item.prevOwnerTimeSpent}
                dataSource={events.filter((i) => i.round == round).slice(0, maxThroneTakenEvents)}
                renderItem={item => {
                    return (
                        <List.Item>

                            {
                                item.type == EVENT_THRONE_TAKEN ?
                                    <div style={{ width: '100%', marginTop: 6 }}>
                                        <div style={{ float: 'left' }}><Address address={item.newOwner} ensProvider={mainnetProvider} />
                                            &nbsp;captured the {ethrone}!</div>
                                        <div style={{ float: 'right' }}>{item.timestamp && !isNaN(item.timestamp) ? <Text type="secondary">{((parseInt(Date.now() / 1000)) - item.timestamp).simpleTime()} ago</Text> : null}</div>
                                    </div>
                                    : item.type == EVENT_TIME_BOOSTER ?
                                        <div style={{ width: '100%', marginTop: 6 }}>
                                            <div style={{ float: 'left' }}><Address address={item.user} ensProvider={mainnetProvider} />
                                                &nbsp;used a &nbsp;🚀&nbsp; {item.bonusUsed}h Time Booster!</div>
                                            <div style={{ float: 'right' }}>{item.timestamp && !isNaN(item.timestamp) ? <Text type="secondary">{((parseInt(Date.now() / 1000)) - item.timestamp).simpleTime()} ago</Text> : null}</div>
                                        </div>
                                        : null
                            }

                        </List.Item>
                    );
                }}
            />
        </Card>
    );

}