import { Button, Card, DatePicker, Divider, Input, List, Popover, Progress, Slider, Space, Spin, Switch, Tooltip, Typography, Modal } from "antd";


export default function HowItWorks({
    ethrone, ethleader, ethsupreme
}) {

    const links = (
        <Card title="The game engine" type="inner"  style={{marginTop: 24}}>
            The source code of the smart contract powering the game is <b>open source and verified on <a href="https://polygonscan.com/address/0xf41ea5016be2e2d730c31911ca08849b67c8dc2f#code" target="_blank">Polyscan</a></b>.
            <br/><br/>
            It is responsible for managing the rounds and storing each player's score.
            The logic to end the round and transfer the prize is automatically called every 24h via a the <a href="https://medium.com/@ethronegame/ethrone-integrates-chainlink-keepers-to-automate-its-core-game-mechanics-7c29153887f">Chainlink Keeper service</a>.
            <br/><br/>
            Once the game starts, a new round will be started exactly every 24h without human intervention.
            The contract ensures that it is not possible to end a round before the designated time.
        </Card>
    )

    const gameRules = (
        <Card title="How the game works" type="inner"  style={{marginTop: 24}}>
            <div>
                Taking the {ethrone} requires sending a transaction on the blockchain to claim it.
                The game ensures that <b>only one single player can own the {ethrone} for a given block</b> and securely manages the transactions.
                <br /><br />
                Every transaction on the blockchain has a value and a fee to pay for the gas used.
                <br /><br />
                <ul>
                    <li>
                        <b>Your personal army budget is the transaction value, which gets added to the prize pool</b>, it is fixed and the same for everyone.
                    </li>
                    <li>
                        <b>The mercenary budget is the gas fee</b>, and that is variable as with any transaction. Setting higher gas fees gets you faster transactions.
                    </li>
                </ul>
                Since there can only be one player on the {ethrone} for a given block, if multiple attacks (transactions) are sent within the same block, <b>only one will succeed</b>.
                The player that succeeds gets a guaranteed time on the throne, at a minimum it will be the time it takes to mine the next block.
                Ultimately the network decides who takes the throne each block, using a proven decentralized process, driven by gas fees.
                <br />
                <br />
                Does the mercenary analogy make sense now?
                <br />
                <br />
                The game records the time spent on the {ethrone} for each player, and players can re-take it only a limited number of times per round.
                <b> A round lasts 24h, and when it finishes, whoever accumulated the most time on the {ethrone} automatically gets transfered the prize pool.</b>
                <br />
                <br />
                A new round immediately begins after the last one, so there will always be one winner per day.
                The winner takes 90% of the prize pool, and 10% goes back into the game to cover the costs of running it and ongoing development.
                If two or more players have the exact same time (in seconds) at the end of the round, the player that took the {ethrone} first wins.
                <br />
                <br />
            </div>
        </Card>
    )

    const fairness = (
        <Card title="Game design to ensures fairness" type="inner" style={{marginTop: 24}}>
            In order to keep the game fair and secure, the rules are <b>designed in a way that incentivizes miners to keep the game running, rather than to exploit it</b>.
            In a decentralized network, miners have a certain decision power on how transactions are executed.
            It is technically possible that all miners agree to stall the game to allow one player to win.
            <br /><br />
            But since the game makes use of gas fees as an important mechanic, stalling the game would mean refusing to process high value transactions.
            It is extremely unlikely that all miners would agree to pass on high value transactions unanimously, just for a comparatively small daily prize gain.
            It is in the miners interest to keep the game running fairly, letting players compete with each other with high value transactions.
            <br /><br />
            Another attack vector would be to try to automate taking the {ethrone} with a bot that continuously tries to send transactions to the contract.
            The rules makes it much harder to create such a bot by limiting the number of attempts per player per round.
            This limitation encourages smart moves rather than brute force, and opens up interesting strategic thinking.
            <br /><br />
        </Card>
    )

    return (
        <div style={{ width: 600, margin: '64px auto', textAlign: 'left' }}>
            {links}
            {gameRules}
            {fairness}
            <br />
            <br />
        </div>
    );
}