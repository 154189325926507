import { SyncOutlined } from "@ant-design/icons";
import { BigNumber, ethers, utils } from "ethers";
import { Button, Card, Typography, Divider, Input, List, Popover, Progress, Slider, Space, Spin, Switch, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Address, Balance } from "../components";
import { MainGameCard, LeaderboardCard, TimeLeftCard, EventsCard, UserInfoCard } from "../game";
import { gql, useQuery } from "@apollo/client";
import {
    usePoller
} from "../hooks";
import { NETWORKS } from "../constants";
import Notification from "react-web-notification";
import RoundResultsCard from "../game/RoundResultsCard";

const minPollTime = 20000;

const EVENT_THRONE_TAKEN = "throne_taken"
const EVENT_TIME_BOOSTER = "time_booster"

export default function GameUI({
    address,
    mainnetProvider,
    localProvider,
    targetNetwork,
    price,
    tx,
    readContracts,
    writeContracts,
    ethrone,
    ethleader,
    ethsupreme,
}) {

    const [showNotif, setShowNotif] = useState(false);
    const [isShowingNotif, setShowingNotif] = useState(false);

    // Current owner
    const [loadingCurrentOwner, setLoadingCurrentOwner] = useState(true);
    const [currentOwner, setOwner] = useState();
    const fetchCurrentOwner = () => {
        if (readContracts) {
            readContracts.EthroneContract.currentOwner()
                .then((owner) => {
                    setOwner(owner)
                    setLoadingCurrentOwner(false)
                })
                .catch((e) => console.log("Error fetching owner", e));
        }
    }
    usePoller(fetchCurrentOwner, minPollTime, readContracts);

    // Owner time spent
    const [timeSpent, setTimeSpent] = useState();
    const fetchTimeSpent = () => {
        if (readContracts) {
            readContracts.EthroneContract.currentTimeSpent()
                .then((time) => {
                    setTimeSpent(time)
                })
                .catch((e) => console.log("Error fetching time spent", e));
        }
    }
    usePoller(fetchTimeSpent, minPollTime, readContracts && currentOwner);

    // Current user time spent
    const [userTimeSpent, setUserTimeSpent] = useState();
    const fetchUserTimeSpent = () => {
        if (readContracts) {
            readContracts.EthroneContract.totalTimeSpent(address)
                .then((time) => {
                    setUserTimeSpent(time)
                })
                .catch((e) => console.log("Error fetching user time spent", e));
        }
    }
    usePoller(fetchUserTimeSpent, minPollTime, address && readContracts && currentOwner);

    // Player attempts
    const [playerAttempts, setPlayerAttempts] = useState();
    const fetchPlayerAttempts = () => {
        if (readContracts) {
            readContracts.EthroneContract.numberOfAttemts(address)
                .then((time) => {
                    setPlayerAttempts(time)
                })
                .catch((e) => console.log("Error fetching player attempts", e));
        }
    }
    usePoller(fetchPlayerAttempts, minPollTime, address && readContracts && currentOwner);

    // Player time booster
    const [playerTimeBooster, setPlayerTimeBooster] = useState(0);
    const fetchPlayerTimeBooster = () => {
        if (readContracts) {
            readContracts.EthroneContract.timeBonus(address)
                .then((time) => {
                    setPlayerTimeBooster(time)
                })
                .catch((e) => console.log("Error fetching player time booster", e));
        }
    }
    usePoller(fetchPlayerTimeBooster, minPollTime, address && readContracts && currentOwner);

    // Time in round
    const [timeInRound, setTimeInRound] = useState();
    const fetchTimeInRound = () => {
        if (readContracts) {
            readContracts.EthroneContract.currentRoundTime()
                .then((time) => {
                    setTimeInRound(time)
                })
                .catch((e) => console.log("Error fetching time in round", e));
        }
    }
    usePoller(fetchTimeInRound, minPollTime, readContracts && currentOwner);

    // Total players
    const [totalPlayers, setTotalPlayers] = useState();
    const fetchTotalPlayers = () => {
        if (readContracts) {
            readContracts.EthroneContract.totalParticipants()
                .then((t) => {
                    setTotalPlayers(t)
                })
                .catch((e) => console.log("Error fetching participants", e));
        }
    }
    usePoller(fetchTotalPlayers, minPollTime, readContracts && currentOwner);

    // round
    const [round, setRound] = useState();
    const fetchRound = () => {
        if (readContracts) {
            readContracts.EthroneContract.round()
                .then((r) => {
                    setRound(r);
                })
                .catch((e) => console.log("Error fetching round", e));
        }
    }
    useEffect(() => {
        fetchRound();
    }, [readContracts])

    // contract owner
    const [contractOwner, setContractOwner] = useState();
    const fetchContractOwner = () => {
        if (readContracts) {
            readContracts.EthroneContract.contractOwner()
                .then((addr) => {
                    setContractOwner(addr);
                })
                .catch((e) => console.log("Error fetching contractOwner", e));
        }
    }
    useEffect(() => {
        fetchContractOwner();
    }, [readContracts])

    const mainDataFecthed = currentOwner && timeSpent
    const [fetchedHistory, setFetchedHistory] = useState(false);
    const [loadingHistory, setLoadingHistory] = useState();
    const [events, setEvents] = useState([]);
    const [winnerEvents, setWinnerEvents] = useState([]);
    const isPolygon = targetNetwork.chainId === NETWORKS.polygon.chainId
    const maxBlocks = isPolygon ? 5000 : 95000;

    // GQL 
    const gqlQuery = `
    {
      throneTakeOvers(orderBy: timestamp, orderDirection: desc, first: 100) {
        prevOwnerTimeSpent
        round
        prevOwner
        newOwner
        timestamp
      }
      winners(orderBy: round, orderDirection: desc, first: 10) {
        addr
        prize
        totalTimeSpent
        round
        totalPlayers
      }
      timeBoosters(orderBy: timestamp, orderDirection: desc, first: 100) {
        user
        bonusUsed
        round
        timestamp
      }
    }
    `;
    const THRONE_GQL = gql(gqlQuery);
    const gqlResult = useQuery(THRONE_GQL);

    useEffect(() => {
        if (isPolygon) {
            setLoadingHistory(gqlResult.loading);
            setFetchedHistory(true)
            if (gqlResult.data && gqlResult.data.throneTakeOvers && gqlResult.data.winners && gqlResult.data.timeBoosters) {
                const thrones = gqlResult.data.throneTakeOvers
                    .map((log) => {
                        return {
                            type: EVENT_THRONE_TAKEN,
                            newOwner: log.newOwner,
                            prevOwner: log.prevOwner,
                            prevOwnerTimeSpent: log.prevOwnerTimeSpent,
                            round: log.round,
                            timestamp: log.timestamp
                        }
                    })


                const boosters = gqlResult.data.timeBoosters
                    .map((log) => {
                        return {
                            type: EVENT_TIME_BOOSTER,
                            user: log.user,
                            bonusUsed: log.bonusUsed,
                            round: log.round,
                            timestamp: log.timestamp
                        }
                    })

                const events = thrones.concat(boosters).sort((a, b) => a.timestamp > b.timestamp ? -1 : 1)
                setEvents(events)

                const winners = gqlResult.data.winners
                    .map((log) => {
                        return {
                            prize: log.prize,
                            round: log.round,
                            winner: log.addr,
                            totalTimeSpent: log.totalTimeSpent
                        }
                    });

                setWinnerEvents(winners)
                setLoadingHistory(false);

            }
        }
    }, [gqlResult.data, gqlResult.loading])

    // EVENTS
    useEffect(() => {
        if (mainDataFecthed && timeSpent && localProvider && readContracts && (fetchedHistory == false)) {
            setLoadingHistory(true);
            const contract = readContracts.EthroneContract;
            const filter = {
                address: contract.address,
                fromBlock: localProvider.blockNumber - maxBlocks,
                toBlock: 'latest',
                topics: []
            }
            setFetchedHistory(true)
            // fetch history
            localProvider.getLogs(filter).then((result) => {
                const logs = result.sort((a, b) => a.blockNumber > b.blockNumber ? -1 : 1)
                    .map((log) => contract.interface.parseLog(log));
                const thrones = logs.filter((log) => log.name == "ThroneTaken")
                    .map((log) => {
                        return {
                            type: EVENT_THRONE_TAKEN,
                            newOwner: log.args.newOwner,
                            prevOwner: log.args.prevOwner,
                            prevOwnerTimeSpent: log.args.prevOwnerTimeSpent,
                            round: log.args.round,
                            timestamp: log.args.timestamp,
                        }
                    });
                const boosters = logs.filter((log) => log.name == "TimeBoosterUsed")
                    .map((log) => {
                        return {
                            type: EVENT_TIME_BOOSTER,
                            user: log.args.user,
                            bonusUsed: log.args.bonusUsed,
                            round: log.args.round,
                            timestamp: log.args.timestamp,
                        }
                    });
                const winners = logs.filter((log) => log.name == "WinnerChosen")
                    .map((log) => {
                        return {
                            prize: log.args.prize,
                            round: log.args.round,
                            winner: log.args.winner,
                            totalTimeSpent: log.args.totalTimeSpent
                        }
                    });
                const events = thrones.concat(boosters).sort((a, b) => a.timestamp > b.timestamp ? -1 : 1)
                setEvents(events);
                setWinnerEvents(winners);
                setLoadingHistory(false);
            }).catch((e) => console.log("Error fetching history", e));
        }
    }, [fetchedHistory, readContracts, localProvider, mainDataFecthed]);

    // pubsub
    useEffect(() => {
        if (!readContracts) {
            return
        }
        const contract = readContracts.EthroneContract;
        if (fetchedHistory == true && loadingHistory == false) {
            // pubsub
            contract.on("ThroneTaken", (prevOwner, newOwner, prevOwnerTimeSpent, round, timestamp) => {
                setEvents(prev => [{
                    type: EVENT_THRONE_TAKEN,
                    newOwner: newOwner,
                    prevOwner: prevOwner,
                    prevOwnerTimeSpent: prevOwnerTimeSpent,
                    round: round,
                    timestamp: timestamp,
                }, ...prev]);
                fetchCurrentOwner();
                // show notification
                if (Number(prevOwner) == Number(address)) {
                    setShowNotif(true);
                }
            });

            contract.on("TimeBoosterUsed", (user, bonusUsed, round, timestamp) => {
                setEvents(prev => [{
                    type: EVENT_TIME_BOOSTER,
                    user: user,
                    bonusUsed: bonusUsed,
                    round: round,
                    timestamp: timestamp,
                }, ...prev]);
                fetchTimeSpent();
            });

            contract.on("WinnerChosen", (winner, prize, totalTimeSpent, round) => {
                setWinnerEvents(prev => [{
                    prize: prize,
                    round: round,
                    winner: winner,
                    totalTimeSpent: totalTimeSpent
                }, ...prev]);
                fetchRound();
                fetchCurrentOwner();
            });
        }
        return () => {
            contract.removeListener("ThroneTaken");
            contract.removeListener("TimeBoosterUsed");
            contract.removeListener("WinnerChosen");
        };
    }, [localProvider, readContracts, fetchedHistory, loadingHistory]);

    const contractAddress = readContracts && readContracts.EthroneContract ? readContracts.EthroneContract.address : 0x0;

    // max attempts info
    const [maxAttempts, setMaxAttempts] = useState();
    useEffect(() => {
        if (readContracts) {
            readContracts.EthroneContract.maxAttempts().then(max => {
                setMaxAttempts(max);
            }).catch((e) => console.log(e))
        }
    }, [readContracts]);
    const remainingAttempts = maxAttempts - playerAttempts;

    // Round info
    const [roundDuration, setRounduration] = useState(0);
    useEffect(() => {
        if (readContracts) {
            readContracts.EthroneContract.roundDuration().then(duration => {
                setRounduration(duration);
            }).catch((e) => console.log(e))
        }
    }, [readContracts])

    const [timeLeftInRound, setTimeLeftInRound] = useState(0);
    useEffect(() => {
        setTimeLeftInRound(Math.max(roundDuration - timeInRound, 0));
    }, [timeInRound, roundDuration])

    // Leaderboard
    const [loadingLeaderboard, setLoadingLeaderboard] = useState(true);
    const [rankedPlayers, setRankedPlayers] = useState([]);
    const refreshLeaderBoard = function () {
        setLoadingLeaderboard(true)
        const fetchDataForEach = function (arr) {
            const promises = arr.map(async (user) => {
                return {
                    user: user,
                    time: await readContracts.EthroneContract.totalTimeSpent(user),
                    attempts: await readContracts.EthroneContract.numberOfAttemts(user),
                    bonus: await readContracts.EthroneContract.timeBonus(user),
                }
            });
            return Promise.all(promises);
        }
        // grab all player addresses from the latest events
        let playerList = events.filter((i) => i.round == round)
            .filter((v, i, a) => a.findIndex(t => (Number(t.newOwner) == Number(v.newOwner))) === i).map(i => i.newOwner)
            .slice(0, 100);

        // fetch time + attempts for each player
        fetchDataForEach(playerList)
            .then(results => results.sort((a, b) => (a.time > b.time) ? -1 : 1))
            .then(results => results.map((i, idx) => {
                return {
                    rank: idx,
                    user: i.user,
                    time: i.time,
                    attempts: i.attempts,
                    bonus: i.bonus,
                }
            }))
            .then(ranks => {
                setRankedPlayers(ranks)
                setLoadingLeaderboard(false)
            })
            .catch(e => console.log("Error fetching leaderboards", e))
    };

    useEffect(() => {
        if (events && round) {
            refreshLeaderBoard();
        }
    }, [events, round])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [currentOwner, remainingAttempts])

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    let isDesktop = (width > 768);

    /************************************************
     * UI
     *************************************************/

    return (
        <>
            <div className='row'>
                {/* LEFT COL */}
                <div className='column'>
                    <div className='leftCol'>
                        <MainGameCard
                            loadingCurrentOwner={loadingCurrentOwner}
                            setLoadingCurrentOwner={setLoadingCurrentOwner}
                            fetchCurrentOwner={fetchCurrentOwner}
                            remainingAttempts={remainingAttempts}
                            currentOwner={currentOwner}
                            timeSpent={timeSpent}
                            playerTimeBooster={playerTimeBooster}
                            address={address}
                            readContracts={readContracts}
                            writeContracts={writeContracts}
                            localProvider={localProvider}
                            mainnetProvider={mainnetProvider}
                            price={price}
                            ethrone={ethrone}
                            ethleader={ethleader}
                            minPollTime={minPollTime}
                            isPolygon={isPolygon}
                            tx={tx}
                            targetNetwork={targetNetwork}
                        />
                        <UserInfoCard
                            fetchUserTimeSpent={fetchUserTimeSpent}
                            userTimeSpent={userTimeSpent}
                            playerAttempts={playerAttempts}
                            maxAttempts={maxAttempts}
                            playerTimeBooster={playerTimeBooster}
                            address={address}
                            writeContracts={writeContracts}
                            localProvider={localProvider}
                            mainnetProvider={mainnetProvider}
                            price={price}
                            ethrone={ethrone}
                            contractOwner={contractOwner}
                            tx={tx}
                        />
                    </div>
                </div>
                {/* RIGHT COL */}
                <div className='column'>
                    <div className='rightCol'>
                        <TimeLeftCard
                            timeLeftInRound={timeLeftInRound}
                            contractAddress={contractAddress}
                            localProvider={localProvider}
                            pric={price}
                        />
                        <LeaderboardCard
                            currentOwner={currentOwner}
                            maxAttempts={maxAttempts}
                            address={address}
                            mainnetProvider={mainnetProvider}
                            loadingLeaderboard={loadingLeaderboard}
                            laoadingHistory={loadingHistory}
                            refreshLeaderBoard={refreshLeaderBoard}
                            rankedPlayers={rankedPlayers}
                        />
                        <EventsCard
                            mainnetProvider={mainnetProvider}
                            loadingHistory={loadingHistory}
                            round={round}
                            events={events}
                            ethrone={ethrone}
                        />
                        <RoundResultsCard
                            mainnetProvider={mainnetProvider}
                            loadingHistory={loadingHistory}
                            localProvider={localProvider}
                            price={price}
                            winnerEvents={winnerEvents}
                            ethrone={ethrone}
                        />
                    </div>
                </div>
                <br />
            </div>
            {isDesktop ? (
                <Notification
                    ignore={isShowingNotif || !showNotif}
                    title={`Someone took the ${ethrone} from you!`}
                    options={{
                        icon: "crown.png"
                    }}
                    onShow={() => {
                        setShowingNotif(true)
                    }}
                    onClose={() => {
                        setShowNotif(false)
                        setShowingNotif(false)
                    }}
                    timeout={0}
                />
            ) : null}
        </>
    );
}

// helpers

String.prototype.simpleTime = function () {
    var sec_num = parseInt(this, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    return `${hours > 0 ? hours + 'h ' : ''} ${minutes > 0 ? minutes + 'm ' : ''} ${minutes == 0 && hours == 0 ? seconds + 's' : ''}`;
}

Number.prototype.simpleTime = function () {
    var sec_num = this;
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    return `${hours > 0 ? hours + 'h ' : ''} ${minutes > 0 ? minutes + 'm ' : ''} ${minutes == 0 && hours == 0 ? seconds + 's' : ''}`;
}

String.prototype.toHHMMSS = function () {
    var sec_num = parseInt(this, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    return `⏱\u00A0 ${hours > 0 ? hours + 'h ' : ''} ${minutes > 0 ? minutes + 'm ' : ''} ${minutes == 0 && hours == 0 ? seconds + 's' : ''}`;
}

Number.prototype.toHHMMSS = function () {
    var sec_num = this;
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    return `⏱\u00A0 ${hours > 0 ? hours + 'h ' : ''} ${minutes > 0 ? minutes + 'm ' : ''} ${minutes == 0 && hours == 0 ? seconds + 's' : ''}`;
}