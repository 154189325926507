import { Card, Typography, List, Divider } from "antd";
import { Address, Balance } from "../components";

const { Text } = Typography;
const maxWinnerChosenEvents = 4;

export default function RoundResultsCard({
    mainnetProvider,
    loadingHistory,
    localProvider,
    price,
    winnerEvents,
    ethrone
}) {
    return (
        <Card title="Previous Rounds Results"
            loading={loadingHistory}
            type="inner"
            style={{ textAlign: "start", width: 600, margin: '0px auto 24px' }}>
            <List
                rowKey={item => item.blockNumber + "_" + item.sender + "_" + item.winner}
                dataSource={winnerEvents.slice(0, maxWinnerChosenEvents)}
                renderItem={item => {
                    return (
                        <List.Item>
                            <div style={{ width: '100%', marginTop: 12 }}>
                                <h3><Address address={item.winner} ensProvider={mainnetProvider} />&nbsp;Won Round #{item.round} &nbsp;🏆</h3>
                                <Divider style={{ margin: 0, marginBottom: 12 }} />
                                <div style={{ float: 'left' }}>Total time spent on the {ethrone}</div>
                                <div style={{ float: 'right' }}>{item.totalTimeSpent.toHHMMSS()}</div>
                                <Divider dashed style={{ margin: 0, marginBottom: 12 }} />
                                <div style={{ float: 'left' }}>Total Prize Received</div>
                                <div style={{ float: 'right' }}><Balance balance={item.prize} provider={localProvider} price={price} /></div>
                                <Divider dashed style={{ margin: 0, marginBottom: 12 }} />
                            </div>
                        </List.Item>
                    );
                }}
            />
        </Card>
    );

}