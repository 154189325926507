import { Card, Divider } from "antd";
import { Balance } from "../components";
import React, {  } from "react";

export default function TimeLeftCard({
    timeLeftInRound,
    contractAddress,
    localProvider,
    price
}) {
    return (
        <Card
            title="Round Info"
            type="inner"
            style={{ textAlign: "start", width: 600, margin: '0px auto 24px' }} >
            <div>
                <div style={{ float: 'left' }}><h3>🏆&nbsp;&nbsp;Winner gets</h3></div>
                <div style={{ float: 'right' }}><h3><a href="https://opensea.io/assets/matic/0xcfc89ff9e62a9c60f2b9af53aff6a7cf30fb1559/2" target="_blank">NFT Trophy</a> + <Balance address={contractAddress} provider={localProvider} price={price} /></h3></div>
                <Divider style={{ margin: `8px 0px` }} />
                <div style={{ float: 'left' }}><h3>⏱&nbsp;&nbsp;Time left</h3></div>
                <div style={{ float: 'right' }}><h3>{!timeLeftInRound || isNaN(timeLeftInRound) ? "-" : timeLeftInRound.simpleTime()}</h3></div>
            </div>
        </Card>
    )
}