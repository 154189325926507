import { Button, Card, DatePicker, Divider, Input, List, Popover, Progress, Slider, Space, Spin, Switch, Tooltip, Typography, Modal } from "antd";
const { Text, Link } = Typography;

export default function Rules({
    ethrone, ethleader, ethsupreme
}) {
    const gameRules = (
        <Card
            title="Basic Gameplay"
            type="inner"
            style={{ marginBottom: '24px' }}>
            <h3>Strategize when to capture the {ethrone} to maximize the time spent on it!</h3>
            <br />
            <ul>
                <li>
                    Launch an attack to capture the {ethrone}.
                </li>
                <li>
                    Time spent on the {ethrone} will be accumulated and added to your total score.
                </li>
                <li>
                    Each capture adds to a common prize pool.
                </li>
                <li>
                    Players get a limited number of captures per round.
                </li>
                <li>
                    Each round lasts 24h.
                </li>
                <li>
                    The player that accumulates the longest time on the {ethrone} at the end of the round wins the prize pool!
                </li>
            </ul>
            <br />
            <h3>⚠️ The winner is the player who accumulates the longest time on the {ethrone}, not the last one to capture it! ⚠️</h3>
        </Card>
    )

    const timeBoosters = (
        <Card
            title="Time Boosters"
            type="inner"
            style={{ marginBottom: '24px' }}>
            <h3>You get a free 1h Time Booster &nbsp;🚀&nbsp; after every round that you play and <b>don't win</b>.</h3>
            <br/>
            <ul style={{ textAlign: 'left' }}>
                <li><Text>Time Boosters instantly increase your total time on the {ethrone}</Text></li>
                <li><Text>Time Boosters can only be consumed all at once - use them wisely!</Text></li>
            </ul>
            <br/>
            <h3>With Time Boosters &nbsp;🚀&nbsp; the more rounds you play the more advantage you get over the other players!</h3>
        </Card>
    )

    const objectiveCard = (
        <Card
            title="Objectives"
            type="inner"
            style={{ marginBottom: '24px' }}>
            <h2>⚔️&nbsp;<Text type="danger">Take</Text> the {ethrone}<br />
                ⏱&nbsp;<Text type="danger">Hold it</Text> for as long you can to win!</h2>
        </Card>
    );

    const strats = (
        <Card
            title="Strategies"
            type="inner"
            style={{ marginBottom: '24px' }}>
            In order to maximize the time spent on the throne, you need to watch and react to other player's moves.
            All information for the current round is visible on the <b>Leaderboard</b>:
            <br /><br />
            <ul>
                <li>
                    ⏱&nbsp; Each player's time spent on the {ethrone}
                </li>
                <li>
                    ⚔️&nbsp; The number of attacks they have left
                </li>
                <li>
                    🚀&nbsp; The number of time boosters they have
                </li>
                <li>
                    👑&nbsp; Who is the current {ethleader}
                </li>
            </ul>
            <br />
            Use this information wisely!
            <br /><br />
            For example - if you see a player that is currently in the lead with 1h spent on the {ethrone} but has no attempts left to use - you can easily outplay them since they won't be able to counter attack!
            As long as there is enough time left in the round for you to accumulate more than 1h on the throne of course.
            <br /><br />
            You have a limited number of captures per round, which means if you're getting overtaken by other players, you can choose to counter attack immediately or save your ⚔️&nbsp; attacks for later in the round when other players start running out!
            It all depends on how much time you and other players have accumulated, and how much time is left in the round.
            <br /><br />
            <h3>Keep an eye out for other player's moves and play accordingly! Let the best tactics win!</h3>
        </Card>
    )

    return (
        <div style={{ width: 600, margin: '64px auto', textAlign: 'left' }}>
            {objectiveCard}
            {gameRules}
            {timeBoosters}
            {strats}
            <br />
            <br />
        </div>
    );
}