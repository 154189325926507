import { Button, Card, Divider, Popover, Typography } from "antd";
import { Address, Balance } from "../components";
import { BigNumber, ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { usePoller } from "../hooks";

const { Text, Link } = Typography;
const DEFAULT_THRONE_COST = "0.01"

export default function MainGameCard({
    loadingCurrentOwner,
    setLoadingCurrentOwner,
    fetchCurrentOwner,
    remainingAttempts,
    currentOwner,
    timeSpent,
    playerTimeBooster,
    address,
    readContracts,
    writeContracts,
    localProvider,
    mainnetProvider,
    price,
    ethrone,
    ethleader,
    minPollTime,
    isPolygon,
    tx,
    targetNetwork
}) {

    // GAS stuff
    const [baseFeePerGas, setBaseFeePerGas] = useState();
    const gwei = 10 ** 9; // 1 gwei
    const minTip = 1 * gwei;
    const maxTip = 30 * gwei
    async function getFeeData() {
        if (localProvider) {
            try {
                const feeData = await localProvider.getFeeData();
                setBaseFeePerGas(feeData.maxFeePerGas ? feeData.maxFeePerGas.toNumber() : feeData.gasPrice);
            } catch (err) {
                console.log(err)
            }
        }
    };
    usePoller(getFeeData, minPollTime, localProvider);
    const [tipValuePerGas, setTipValuePerGas] = useState(minTip);
    const [estimatedGas, setEstimatedGas] = useState(100_000);
    const maxFeePerGas = BigNumber.from(baseFeePerGas ? baseFeePerGas : 0).add(BigNumber.from(tipValuePerGas ? tipValuePerGas : 0)).toNumber()
    const mercenaryBudget = BigNumber.from(maxFeePerGas).mul(BigNumber.from(estimatedGas));

    const [throneCost, setThroneCost] = useState();
    useEffect(() => {
        if (readContracts) {
            readContracts.EthroneContract.throneCost().then(c => {
                setThroneCost(c);
            }).catch((e) => console.log(e))
        }
    }, [readContracts]);

    // Gas estimate
    useEffect(() => {
        if (writeContracts && throneCost) {
            writeContracts.EthroneContract.estimateGas.takeThrone({ value: throneCost }).then(g => {
                console.log("Estimated gas units", g)
                setEstimatedGas(g);
            }).catch(e => {
                console.log("Couldn't estimate gas price", e);
            });
        }
    }, [writeContracts, throneCost]);

    // TOOLTIPS

    const mainArmyTooltip = (
        <div style={{ width: 300 }}>
            1. Personal army budget goes into the prize pool (transaction value). Whoever stays longest on the {ethrone} at the end of the round gets the prize pool!
            <br />
            <br />
            2. If attack fails, the personal army budget goes back to you (reverted transaction).
        </div>
    )

    const mercenaryTooltip = (
        <div style={{ width: 300 }}>
            1. Mercenary budget does not go in the prize pool (transaction fee). It includes the base fee and reward (transaction tip) required to launch the attack.
            <br />
            <br />
            2. Base price of mercenaries change all the time! Optimize your attack by finding the lowest prices.
            <br />
            <br />
            3. If the attack fails, most of the budget goes back to you.
        </div>
    )

    const mercenaryTipTooltip = (
        <div style={{ width: 300 }}>
            1. If multiple players attack at the same time, the highest paid mercenaries are more likely to win (transaction priority).
            <br />
            <br />
            2. A low mercenary reward (transaction tip) can sometimes be a good strategy too, play smart!
        </div>
    )

    return (
        <Card loading={loadingCurrentOwner}
            style={{ width: 600, margin: '0px auto 24px' }}>

            {currentOwner == 0x0 ? (
                <div>
                    <h1 style={{ marginBottom: 0 }}>The {ethrone} is empty!</h1>
                    <h4 style={{ marginBottom: 24 }}>Now is your chance to grab it!</h4>
                    <img src="empty_throne.png" width={300} className='crown' />
                </div>
            ) : currentOwner == address ? (
                <div>
                    <h1 style={{ marginTop: 0 }}>You are the {ethleader}!</h1>
                    <img src="crown.png" width={300} className='crown' />
                    <h4>You spent {(timeSpent ? timeSpent : "0").toHHMMSS()} on the {ethrone} so far!</h4>
                    <Text type="secondary">Stay the longest in the round to win the prize!</Text>
                </div>
            ) :
                <div>
                    <h1 style={{ marginBottom: 24 }}><Address address={currentOwner} ensProvider={mainnetProvider} /> is the {ethleader}</h1>
                    <img src="occupied_throne.png" width={300} className='crown' />
                    <h4 style={{ marginTop: 24 }}>They've been on the {ethrone} for {(timeSpent ? timeSpent : "0").toHHMMSS()}</h4>
                    <Text type="secondary">Don't let them win the prize!</Text>
                </div>
            }

            {/* COMMANDS */}
            {currentOwner != address && remainingAttempts == 0 ? (
                <div>
                    <Divider />
                    <h2>You've used all your ⚔️&nbsp; attacks this round!</h2>
                    <h4>New round starting in {!timeLeftInRound || isNaN(timeLeftInRound) ? "-" : timeLeftInRound.toHHMMSS()}</h4>
                </div>
            ) : currentOwner != address ? (
                <Card bordered={false} style={{ marginTop: 32 }} loading={!remainingAttempts}>
                    <h1>Plan Your Attack | {remainingAttempts} ⚔️&nbsp;left</h1>
                    <Divider style={{ margin: 0, marginBottom: 24 }} />
                    <div style={{ float: 'left' }}><h3><Popover title="Game tips" content={mainArmyTooltip}>👩‍✈️&nbsp; Personal Army Budget<span>&#10067;</span></Popover></h3></div>
                    <div style={{ float: 'right' }}><h3><Balance balance={throneCost ? throneCost : ethers.utils.parseEther(DEFAULT_THRONE_COST)} provider={localProvider} price={price} /></h3></div>
                    <Divider dashed style={{ margin: 0, marginBottom: 12 }} />
                    {baseFeePerGas ? (
                        <div style={{ marginTop: 16 }}>
                            <div style={{ float: 'left' }}><h3><Popover title="Game tips" content={mercenaryTooltip}>🥷&nbsp; Mercenaries Budget<span>&#10067;</span></Popover></h3></div>
                            <div style={{ float: 'right' }}><h3><Balance balance={mercenaryBudget} provider={localProvider} price={price} /></h3></div>
                            <Divider dashed style={{ margin: 0, marginBottom: 24 }} />
                            <h4>Current base price per mercenary: <a href={isPolygon ? "https://polygonscan.com/gastracker" : "https://ethgasstation.info/"} target="_blank">{parseFloat(ethers.utils.formatUnits(baseFeePerGas, 'gwei')).toFixed(2)} gwei</a></h4>
                            <Text type="secondary">Mercenaries required (estimate): {parseInt(estimatedGas / 1000, 10)}k</Text>
                        </div>
                    ) : (
                        <div style={{ float: 'left' }}>Calculating...</div>
                    )}
                    <br />
                    <Button
                        type="primary"
                        size="large"
                        shape="round"
                        style={{ width: 500, margin: 'auto' }}
                        disabled={remainingAttempts == 0}
                        onClick={async () => {
                            console.log("📡 Transaction Start:", maxFeePerGas, tipValuePerGas);

                            const metadata = targetNetwork.isEIP1559 ?
                                {
                                    value: throneCost ? throneCost : ethers.utils.parseEther(DEFAULT_THRONE_COST),
                                    maxFeePerGas: maxFeePerGas,
                                    maxPriorityFeePerGas: tipValuePerGas
                                }
                                : {
                                    value: throneCost ? throneCost : ethers.utils.parseEther(DEFAULT_THRONE_COST),
                                    gasPrice: maxFeePerGas + tipValuePerGas,
                                }

                            const result = tx(writeContracts.EthroneContract.takeThrone(metadata), update => {
                                console.log("📡 Transaction Update:", update);
                                if (update && (update.status === "confirmed" || update.status === 1)) {
                                    console.log(" 🍾 Transaction " + update.hash + " finished!");
                                    console.log(
                                        " ⛽️ " +
                                        update.gasUsed +
                                        "/" +
                                        (update.gasLimit || update.gas) +
                                        " @ " +
                                        parseFloat(update.gasPrice) / 1000000000 +
                                        " gwei",
                                    );
                                    fetchCurrentOwner();
                                } else if (update && (update.status === "pending")) {
                                    setLoadingCurrentOwner(true);
                                } else if (update && (update.data == "Reverted" || update.error != null || update.code != null)) {
                                    console.log("Transaction reverted", update);
                                    fetchCurrentOwner();
                                }
                            });

                            console.log("awaiting metamask/web3 confirm result...", result);
                            console.log(await result);
                        }}
                    >
                        {tipValuePerGas ? <span>Take the {ethrone}</span> : <span>Adjust your mercenary reward to attack</span>}
                    </Button>
                </Card>
            ) : currentOwner == address ? (
                <div>
                    <Divider />
                    <h2>Available Time Booster &nbsp;🚀&nbsp; {playerTimeBooster}h</h2>
                    <ul style={{ textAlign: 'left' }}>
                        <li><Text>Time Boosters instantly increase your total time on the {ethrone}</Text></li>
                        <li><Text>Time Boosters can only be consumed all at once - use them wisely!</Text></li>
                        <li><Text>You get a free 1h Time Booster after every round that you play</Text></li>
                    </ul>

                    <Button
                        type="primary"
                        size="large"
                        shape="round"
                        style={{ width: 500, margin: '12px auto' }}
                        disabled={playerTimeBooster == 0}
                        onClick={async () => {
                            await tx(writeContracts.EthroneContract.consumeTimeBonus());
                        }}
                    >
                        {playerTimeBooster > 0 ? <>Use your 🚀&nbsp; {playerTimeBooster}h Time Booster</> : "No Time Booster Available"}
                    </Button>
                </div>
            ) : null
            }
        </Card>
    );

}