import React, { useState } from "react";
import { NETWORKS } from "../constants";
import { useBalance } from "../hooks";

const { utils } = require("ethers");

/*
  ~ What it does? ~

  Displays a balance of given address in ether & dollar

  ~ How can I use? ~

  <Balance
    address={address}
    provider={mainnetProvider}
    price={price}
  />

  ~ If you already have the balance as a bignumber ~
  <Balance
    balance={balance}
    price={price}
  />

  ~ Features ~

  - Provide address={address} and get balance corresponding to given address
  - Provide provider={mainnetProvider} to access balance on mainnet or any other network (ex. localProvider)
  - Provide price={price} of ether and get your balance converted to dollars
*/

export default function Balance(props) {
  const [dollarMode, setDollarMode] = useState(false);

  // const [listening, setListening] = useState(false);

  const balance = useBalance(props.provider, props.address);

  let floatBalance = parseFloat("0.00");

  let usingBalance = balance;

  if (typeof props.balance !== "undefined") {
    usingBalance = props.balance;
  }
  if (typeof props.value !== "undefined") {
    usingBalance = props.value;
  }

  if (usingBalance) {
    const etherBalance = utils.formatEther(usingBalance);
    parseFloat(etherBalance).toFixed(2);
    floatBalance = parseFloat(etherBalance);
  }

  let displayBalance = floatBalance.toFixed(3);
  let showEthSymbol = !dollarMode;
  const price = props.price || props.dollarMultiplier;

  let chainId = props.provider && props.provider._network ? props.provider._network.chainId : 1;
  let isMatic = chainId === NETWORKS.polygon.chainId || chainId === NETWORKS.mumbai.chainId

  if (price && dollarMode) {
    displayBalance = "$" + (floatBalance * price).toFixed(2);
  } else {
    displayBalance = displayBalance + (isMatic ? " MATIC" : " ETH")
  }

  let icon = "ethereum.svg"
  if (isMatic) {
    icon = "polygon.svg"
  }

  return (
    <a>
      <span
        style={{
          verticalAlign: "middle",
          //fontSize: props.size ? props.size : 16,
          padding: 0,
          cursor: "pointer",
          color: "#FFFFFF"
        }}
        onClick={() => {
          setDollarMode(!dollarMode);
        }}
      >
        {showEthSymbol ? (<img src={icon} height='18' style={{ marginBottom: 4, marginRight: 6 }} />) : null}
        {displayBalance}
      </span>
    </a>
  );
}
