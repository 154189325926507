import { Fetcher, Route, Token, WETH } from "@uniswap/sdk";
import { usePoller } from "eth-hooks";
import { useState } from "react";

export default function useExchangePrice(targetNetwork, pollTime) {
  const [price, setPrice] = useState(0);
  const pollPrice = () => {
    async function getPrice() {
      let currency = targetNetwork.nativeCurrency
      console.log("FETCHING PRICES");
      fetch(`https://api.coinbase.com/v2/exchange-rates?currency=${currency}`)
        .then(response => response.json())
        .then(json => {
          const price = json.data.rates.USD;
          setPrice(parseFloat(price));
        }).catch(e => {
          console.log("Error fetching USD price", e)
        })
    }
    getPrice();
  };
  usePoller(pollPrice, pollTime || 60000);

  return price;
}
