import { Button, Card, Divider, Typography } from "antd";
import { Address, Balance } from "../components";
import { SyncOutlined } from "@ant-design/icons";

const { Text } = Typography;

export default function UserInfoCard({
    fetchUserTimeSpent,
    userTimeSpent,
    playerAttempts,
    maxAttempts,
    playerTimeBooster,
    address,
    writeContracts,
    localProvider,
    mainnetProvider,
    price,
    ethrone,
    contractOwner,
    tx
}) {

    return (
        <Card title="Your Stats"
            type="inner" style={{ textAlign: "start", width: 600, margin: '0px auto 24px' }}
            extra={(<a onClick={fetchUserTimeSpent}><SyncOutlined /></a>)}>
            <h3>Competing as <Address address={address} ensProvider={mainnetProvider} /></h3>
            <Divider style={{ margin: 0, marginBottom: 24 }} />
            <div style={{ float: 'left' }}>Time spent on the {ethrone} this round</div>
            <div style={{ float: 'right' }}>{(userTimeSpent ? userTimeSpent : "0").toHHMMSS()}</div>
            <Divider dashed style={{ margin: 0, marginBottom: 12 }} />
            <div style={{ float: 'left' }}>Successful attacks this round</div>
            <div style={{ float: 'right' }}>⚔️&nbsp; {playerAttempts} / {maxAttempts}</div>
            <Divider dashed style={{ margin: 0, marginBottom: 12 }} />
            <div style={{ float: 'left' }}>Time Booster</div>
            <div style={{ float: 'right' }}>🚀&nbsp; {`${playerTimeBooster}h`}</div>
            <Divider dashed style={{ margin: 0, marginBottom: 12 }} />
            <div style={{ float: 'left' }}>Your Balance</div>
            <div style={{ float: 'right' }}><Balance address={address} provider={localProvider} price={price} /></div>
            <Divider dashed style={{ margin: 0, marginBottom: 12 }} />

            {(localProvider._network && localProvider._network.chainId === 31337) || (contractOwner && address === contractOwner) ? (
                <Button
                    type="primary"
                    size="large"
                    shape="round"
                    style={{ width: 500, margin: 'auto' }}
                    onClick={async function () {
                        await tx(writeContracts.EthroneContract.awardPrize());
                    }}
                >
                    Award winner (debug)
                </Button>
            ) : null}
        </Card>
    );

}