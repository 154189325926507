import { PageHeader } from "antd";
import React from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";

// displays a page header

export default function Header({footer}) {
  const {currentTheme} = useThemeSwitcher();
  return (
      <PageHeader
        title={(<a href="https://twitter.com/ethronegame" target="_blank" style={{ color: "#FFF", verticalAlign: 'center' }}><img src="ic-ethrone.svg" style={{ width: 36, transform: 'translateY(-13%)', marginRight: 10, fill: '#FF0000' }}/>Ethrone </a>)}
        subTitle="Stay on it to win the prize!"
        footer={footer}
      />
   
  );
}
