import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";
import { Alert, Button, Col, Menu, Row, Dropdown, Divider, Typography } from "antd";
import { DownOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";
import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import Web3Modal from "web3modal";
import "./App.css";
import { Account, Faucet, Header } from "./components";
import { INFURA_ID, NETWORK, NETWORKS } from "./constants";
import { Transactor } from "./helpers";
import { useThemeSwitcher } from "react-css-theme-switcher";
import {
  useContractLoader,
  useExchangePrice,
  useGasPrice,
  useUserSigner,
} from "./hooks";
import Portis from "@portis/web3";
import Fortmatic from "fortmatic";
import Authereum from "authereum";
import GameUI from "./views/GameUi";
import HowToPlay from "./views/HowToPlay";
import HowItWorks from "./views/HowItWorks";

const { Text } = Typography;
const { ethers } = require("ethers");

const DEBUG = false;

/// Chain selection
const prevNetworkJSON = window.localStorage.getItem("network");
var defaultChain = NETWORKS.polygon
try {
  defaultChain = JSON.parse(prevNetworkJSON);
} catch (err) {
  console.log("Coudn't parse network data", prevNetworkJSON)
}
// const targetNetwork = DEBUG ? NETWORKS.localhost : prevNetwork ? prevNetwork : NETWORKS.polygon; //NETWORKS.mumbai; //NETWORKS.localhost; // <------- select your target frontend network (localhost, rinkeby, xdai, mainnet)
const targetNetwork = DEBUG ? NETWORKS.localhost : NETWORKS.polygon;

const NETWORKCHECK = true;

// 🛰 providers
const mainnetProvider = new ethers.providers.InfuraProvider(NETWORKS.mainnet, INFURA_ID);
const localProviderUrl = targetNetwork.rpcUrl;
const localProviderUrlFromEnv = process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : localProviderUrl;
if (DEBUG) console.log("🏠 Connecting to provider:", localProviderUrlFromEnv);
const localProvider = targetNetwork.chainId === NETWORKS.localhost.chainId ? new ethers.providers.JsonRpcBatchProvider(localProviderUrlFromEnv)
  : targetNetwork.chainId === NETWORKS.mainnet.chainId ? new ethers.providers.InfuraWebSocketProvider(targetNetwork, INFURA_ID)
    : new ethers.providers.JsonRpcBatchProvider(targetNetwork.rpcUrl);


// 🔭 block explorer URL
const blockExplorer = targetNetwork.blockExplorer;

// Coinbase walletLink init
const walletLink = new WalletLink({
  appName: 'coinbase',
});

// WalletLink provider
const walletLinkProvider = walletLink.makeWeb3Provider(
  `https://mainnet.infura.io/v3/${INFURA_ID}`,
  1,
);

// Portis ID: 6255fb2b-58c8-433b-a2c9-62098c05ddc9
/*
  Web3 modal helps us "connect" external wallets:
*/
const web3Modal = new Web3Modal({
  network: "mainnet", // Optional. If using WalletConnect on xDai, change network to "xdai" and add RPC info below for xDai chain.
  cacheProvider: true, // optional
  theme: "dark", // optional. Change to "dark" for a dark theme.
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        bridge: "https://polygon.bridge.walletconnect.org",
        infuraId: INFURA_ID,
        rpc: {
          1: `https://mainnet.infura.io/v3/${INFURA_ID}`, // mainnet // For more WalletConnect providers: https://docs.walletconnect.org/quick-start/dapps/web3-provider#required
          42: `https://kovan.infura.io/v3/${INFURA_ID}`,
          100: "https://dai.poa.network", // xDai
        },
      },

    },
    portis: {
      display: {
        logo: "https://user-images.githubusercontent.com/9419140/128913641-d025bc0c-e059-42de-a57b-422f196867ce.png",
        name: "Portis",
        description: "Connect to Portis App",
      },
      package: Portis,
      options: {
        id: "6255fb2b-58c8-433b-a2c9-62098c05ddc9",
      },
    },
    fortmatic: {
      package: Fortmatic, // required
      options: {
        key: "pk_live_5A7C91B2FC585A17", // required
      },
    },
    'custom-walletlink': {
      display: {
        logo: 'https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0',
        name: 'Coinbase',
        description: 'Connect to Coinbase Wallet (not Coinbase App)',
      },
      package: walletLinkProvider,
      connector: async (provider, options) => {
        await provider.enable();
        return provider;
      },
    },
    authereum: {
      package: Authereum, // required
    }
  },
});

// String helpers
const ethrone = `👑\u00A0 Ethrone`
const ethleader = `✨\u00A0 Ethleader`
const ethsupreme = `🏆\u00A0 Supreme Ethleader`

function App(props) {
  const { switcher, currentTheme, status, themes } = useThemeSwitcher();
  // Avoid theme change flicker
  if (status === "loading") {
    return null;
  }

  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  /* 💵 This hook will get the price of ETH from Coinbase API */
  const price = useExchangePrice(targetNetwork);

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = useGasPrice(targetNetwork, "fast");
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userSigner = useUserSigner(injectedProvider, localProvider);

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [userSigner]);

  // You can warn the user if you would like them to be on a specific network
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userSigner, gasPrice);

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider);

  // If you want to make 🔐 write transactions to your contracts, use the userSigner:
  const writeContracts = useContractLoader(userSigner, { chainId: localChainId });

  const switchNetwork = async function () {
    const ethereum = window.ethereum;
    const data = [
      {
        chainId: "0x" + targetNetwork.chainId.toString(16),
        chainName: targetNetwork.displayName,
        nativeCurrency: {
          symbol: targetNetwork.nativeCurrency,
          decimals: 18
        },
        rpcUrls: [targetNetwork.rpcUrl],
        blockExplorerUrls: [targetNetwork.blockExplorer],
      },
    ];
    console.log("data", data);

    let switchTx;
    // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods
    try {
      switchTx = await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: data[0].chainId }],
      });
    } catch (switchError) {
      // not checking specific error code, because maybe we're not using MetaMask
      try {
        switchTx = await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: data,
        });
      } catch (addError) {
        // handle "add" error
      }
    }

    if (switchTx) {
      console.log(switchTx);
    }
  };

  const networks = [NETWORKS.mainnet, NETWORKS.polygon, NETWORKS.kovan, NETWORKS.mumbai];
  const onClick = ({ key }) => {
    // change networks and reload
    window.localStorage.setItem("network", JSON.stringify(networks[key]));
    window.location.reload();
  };

  const menu = (
    <Menu onClick={onClick} style={{ padding: 12 }}>
      <Text type="secondary" style={{ paddingLeft: 12 }}>Main Networks</Text>
      <Divider style={{ marginTop: 12, marginBottom: 8 }} />
      <Menu.Item key="0"><h4><img src="ethereum.svg" style={{ height: 24, marginRight: 12 }} />{networks[0].displayName} | Play with $ETH</h4></Menu.Item>
      <Menu.Item key="1"><h4><img src="polygon.svg" style={{ height: 24, marginRight: 12 }} />{networks[1].displayName} | Play with $MATIC</h4></Menu.Item>
      <br />
      <Text type="secondary" style={{ paddingLeft: 12 }}>Test Networks</Text>
      <Divider style={{ marginTop: 12, marginBottom: 8 }} />
      <Menu.Item key="2"><h4><img src="ethereum.svg" style={{ height: 24, marginRight: 12 }} />{networks[2].displayName}</h4></Menu.Item>
      <Menu.Item key="3"><h4><img src="polygon.svg" style={{ height: 24, marginRight: 12 }} />{networks[3].displayName}</h4></Menu.Item>
    </Menu>
  );

  const dropdown = (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button
        shape="round"
        type="default"
        size="large"
      >
        {targetNetwork.displayName} <DownOutlined />
      </Button>
    </Dropdown>
  );

  const leftSubHeader = (
    <div style={{ padding: '0px 0px' }}>
      <a href="https://twitter.com/ethronegame" target="_blank"><img src="twitter.png" width="30" style={{ marginRight: 12 }} /></a>
      <a href="https://discord.gg/Y79wQcHfJf" target="_blank"><img src="discord.png" width="36" style={{ marginRight: 12 }} /></a>
    </div>
  )

  let networkDisplay = "";
  if (NETWORKCHECK && localChainId && selectedChainId && localChainId !== selectedChainId) {
    const networkSelected = NETWORK(selectedChainId);
    const networkLocal = NETWORK(localChainId);
    if (selectedChainId === 1337 && localChainId === 31337) {
      networkDisplay = (
        <div style={{ zIndex: 2, position: "absolute", right: 0, top: 120, padding: 16 }}>
          <Alert
            message="⚠️ Wrong Network ID"
            description={
              <div>
                You have <b>chain id 1337</b> for localhost and you need to change it to <b>31337</b> to work with
                HardHat.
                <div>(MetaMask -&gt; Settings -&gt; Networks -&gt; Chain ID -&gt; 31337)</div>
              </div>
            }
            type="error"
            closable={false}
          />
        </div>
      );
    } else {
      networkDisplay = (
        <div style={{ zIndex: 2, position: "absolute", right: 0, top: 120, padding: 16 }}>
          <Alert
            message="⚠️ Wrong Network Detected"
            description={(
              <div>
                You have <b>{networkSelected && networkSelected.displayName}</b> selected and you need to be on <b></b>{networkLocal && networkLocal.displayName}<b />
                <div style={{ textAlign: 'right', marginTop: 12 }}>
                  <Button
                    onClick={switchNetwork}
                  >
                    <b>Switch to {networkLocal && networkLocal.displayName}</b>
                  </Button>
                </div>
              </div>
            )
            }
            type="error"
            closable={false}
          />
        </div>
      );
    }
  } else {
    networkDisplay = null;
  }

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  const [route, setRoute] = useState();
  useEffect(() => {
    setRoute(window.location.pathname);
  }, [setRoute]);

  const faucetAvailable = localProvider && localProvider.connection && targetNetwork.name.indexOf("local") !== -1;

  return (
    <div className="App" >
      <Header footer={(
        <div style={{ textAlign: 'left', paddingBottom: 12 }}>
          {leftSubHeader}
          {networkDisplay}
        </div>
      )} />
      <BrowserRouter>
        <Menu style={{ textAlign: "center" }} selectedKeys={[route]} mode="horizontal">
          <Menu.Item key="/">
            <Link
              onClick={() => {
                setRoute("/");
              }}
              to="/"
            >
              Game
            </Link>
          </Menu.Item>
          <Menu.Item key="/howtoplay">
            <Link
              onClick={() => {
                setRoute("/howtoplay");
              }}
              to="/howtoplay"
            >
              How to play
            </Link>
          </Menu.Item>
          <Menu.Item key="/howitworks">
            <Link
              onClick={() => {
                setRoute("/howitworks");
              }}
              to="/howitworks"
            >
              How it works
            </Link>
          </Menu.Item>
        </Menu>

        <Switch>
          <Route exact path="/">
            <GameUI
              address={address}
              userSigner={userSigner}
              mainnetProvider={mainnetProvider}
              localProvider={localProvider}
              targetNetwork={targetNetwork}
              price={price}
              tx={tx}
              writeContracts={writeContracts}
              readContracts={readContracts}
              ethrone={ethrone}
              ethleader={ethleader}
              ethsupreme={ethsupreme}
            />
          </Route>
          <Route path="/howtoplay">
            <HowToPlay
              ethrone={ethrone}
              ethleader={ethleader}
              ethsupreme={ethsupreme} />
          </Route>
          <Route path="/howitworks">
            <HowItWorks
              ethrone={ethrone}
              ethleader={ethleader}
              ethsupreme={ethsupreme} />
          </Route>
        </Switch>
      </BrowserRouter>

      {/* Account */}
      <div style={{ position: "fixed", textAlign: "right", right: 0, top: 0, padding: 10 }}>
        <Account
          address={address}
          localProvider={localProvider}
          userSigner={userSigner}
          mainnetProvider={mainnetProvider}
          price={price}
          web3Modal={web3Modal}
          loadWeb3Modal={loadWeb3Modal}
          logoutOfWeb3Modal={logoutOfWeb3Modal}
          blockExplorer={blockExplorer}
        />
      </div>

      {/* 🗺 Extra UI like gas price, eth price, faucet, and support: */}
      <div style={{ position: "fixed", textAlign: "left", left: 0, bottom: 20, padding: 10 }}>
        <Row align="middle" gutter={[4, 4]}>
          <Col span={24}>
            {
              /*  if the local provider has a signer, let's show the faucet:  */
              faucetAvailable ? (
                <Faucet localProvider={localProvider} price={price} ensProvider={localProvider} />
              ) : (
                ""
              )
            }
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default App;
